<template>
  <div class="column" style="background: rgb(241, 241, 241);">
    <svg
      t="1662882879901"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1914"
      width="100"
      height="100"
    >
      <path
        d="M780.8 977.066667c41.813333 0 75.093333-33.28 75.093333-75.093334V122.026667c0-41.813333 33.28-75.093333 75.093334-75.093334h-699.733334c-41.813333 0-75.093333 33.28-75.093333 75.093334v855.893333h624.64z"
        fill="#FFFFFF"
        p-id="1915"
      />
      <path
        d="M931.84 46.933333c-41.813333 0-75.093333 33.28-75.093333 75.093334v104.106666H1006.933333V122.026667c0-41.813333-34.133333-75.093333-75.093333-75.093334zM794.453333 976.213333H93.866667c-41.813333 0-75.093333-33.28-75.093334-75.093333v-46.08h700.586667v46.08c0 41.813333 34.133333 75.093333 75.093333 75.093333zM505.173333 735.573333c-93.013333 0-168.106667-53.76-168.106666-146.773333v59.733333c0 93.013333 75.093333 146.773333 168.106666 146.773334 93.013333 0 168.106667-53.76 168.106667-146.773334v-59.733333c0 92.16-75.093333 146.773333-168.106667 146.773333zM723.626667 164.693333c0 13.653333-11.946667 25.6-25.6 25.6H312.32c-14.506667 0-25.6-11.946667-25.6-25.6s11.093333-25.6 25.6-25.6h385.706667c13.653333 0 25.6 11.093333 25.6 25.6z"
        fill="#C7CAC7"
        p-id="1916"
      />
      <path
        d="M674.133333 465.92v122.026667c0 93.013333-75.093333 146.773333-168.106666 146.773333-93.013333 0-168.106667-53.76-168.106667-146.773333V465.92H674.133333z"
        fill="#F65D4F"
        p-id="1917"
      />
      <path
        d="M506.026667 564.906667m-40.106667 0a40.106667 40.106667 0 1 0 80.213333 0 40.106667 40.106667 0 1 0-80.213333 0Z"
        fill="#FFFFFF"
        p-id="1918"
      />
      <path
        d="M931.84 38.4H231.253333c-46.08 0-83.626667 37.546667-83.626666 83.626667v725.333333h-128c-5.12 0-8.533333 3.413333-8.533334 8.533333v46.08c0 46.08 37.546667 83.626667 83.626667 83.626667h58.026667c0.853333 0.853333 2.56 0.853333 3.413333 0.853333h625.493333c3.413333 0 7.68 0 11.093334-0.853333h2.56c1.706667 0 2.56-0.853333 4.266666-0.853333 37.546667-7.68 65.706667-41.813333 65.706667-81.92V233.813333H1006.933333c5.12 0 8.533333-3.413333 8.533334-8.533333V122.026667c0-46.08-37.546667-83.626667-83.626667-83.626667zM164.693333 967.68H93.866667c-36.693333 0-66.56-29.866667-66.56-66.56v-37.546667h683.52v37.546667c0 27.306667 12.8 51.2 33.28 66.56H164.693333z m682.666667-65.706667c0 33.28-23.893333 60.586667-56.32 65.706667-34.986667-1.706667-64-30.72-64-66.56v-46.08c0-5.12-3.413333-8.533333-8.533333-8.533333H164.693333v-725.333334c0-36.693333 29.866667-66.56 66.56-66.56h650.24l-0.853333 0.853334c-1.706667 0.853333-2.56 2.56-4.266667 3.413333-0.853333 0.853333-1.706667 1.706667-2.56 1.706667l-5.12 5.12c0 0.853333-0.853333 0.853333-0.853333 1.706666-1.706667 2.56-3.413333 4.266667-5.12 6.826667-0.853333 0.853333-0.853333 1.706667-1.706667 2.56-0.853333 1.706667-2.56 3.413333-3.413333 5.12-0.853333 0.853333-0.853333 1.706667-1.706667 2.56l-2.56 5.12c-0.853333 0.853333-0.853333 2.56-0.853333 3.413333-0.853333 1.706667-0.853333 3.413333-1.706667 5.12 0 0.853333-0.853333 2.56-0.853333 3.413334-0.853333 1.706667-0.853333 4.266667-0.853333 5.973333 0 0.853333 0 1.706667-0.853334 2.56 0 3.413333-0.853333 5.973333-0.853333 9.386667v782.506666zM998.4 216.746667h-133.12V122.026667c0-36.693333 29.866667-66.56 66.56-66.56s66.56 29.866667 66.56 66.56v94.72z"
        p-id="1919"
      />
      <path
        d="M674.133333 457.386667h-29.013333v-53.76c0-76.8-62.293333-139.946667-139.946667-139.946667-76.8 0-139.946667 62.293333-139.946666 139.946667v53.76h-29.013334c-5.12 0-8.533333 3.413333-8.533333 8.533333v122.026667c0 91.306667 72.533333 155.306667 176.64 155.306666s176.64-64 176.64-155.306666V465.92c1.706667-5.12-1.706667-8.533333-6.826667-8.533333z m-290.986666-53.76c0-67.413333 54.613333-122.88 122.88-122.88s122.88 54.613333 122.88 122.88v53.76H597.333333v-53.76c0-50.346667-40.96-91.306667-91.306666-91.306667-50.346667 0-91.306667 40.96-91.306667 91.306667v53.76H384v-53.76z m197.12 0v53.76H431.786667v-53.76c0-40.96 33.28-74.24 74.24-74.24s74.24 32.426667 74.24 74.24z m85.333333 184.32c0 69.12-49.493333 138.24-159.573333 138.24-110.08 0-159.573333-69.12-159.573334-138.24V474.453333H665.6v113.493334z"
        p-id="1920"
      />
      <path
        d="M506.026667 516.266667c-27.306667 0-48.64 22.186667-48.64 48.64 0 23.893333 17.066667 43.52 40.106666 47.786666v46.933334c0 5.12 3.413333 8.533333 8.533334 8.533333s8.533333-3.413333 8.533333-8.533333v-46.933334C537.6 608.426667 554.666667 588.8 554.666667 564.906667c0-27.306667-22.186667-48.64-48.64-48.64z m0 80.213333c-17.066667 0-31.573333-14.506667-31.573334-31.573333s14.506667-31.573333 31.573334-31.573334 31.573333 14.506667 31.573333 31.573334-14.506667 31.573333-31.573333 31.573333zM582.826667 173.226667h17.066666c5.12 0 8.533333-3.413333 8.533334-8.533334s-3.413333-8.533333-8.533334-8.533333h-17.066666c-5.12 0-8.533333 3.413333-8.533334 8.533333s3.413333 8.533333 8.533334 8.533334zM497.493333 173.226667h17.066667c5.12 0 8.533333-3.413333 8.533333-8.533334s-3.413333-8.533333-8.533333-8.533333h-17.066667c-5.12 0-8.533333 3.413333-8.533333 8.533333s3.413333 8.533333 8.533333 8.533334zM352.426667 164.693333c0-5.12-3.413333-8.533333-8.533334-8.533333h-17.066666c-5.12 0-8.533333 3.413333-8.533334 8.533333s3.413333 8.533333 8.533334 8.533334h17.066666c5.12 0 8.533333-4.266667 8.533334-8.533334zM685.226667 156.16h-17.066667c-5.12 0-8.533333 3.413333-8.533333 8.533333s3.413333 8.533333 8.533333 8.533334h17.066667c5.12 0 8.533333-3.413333 8.533333-8.533334s-3.413333-8.533333-8.533333-8.533333zM412.16 173.226667h17.066667c5.12 0 8.533333-3.413333 8.533333-8.533334s-3.413333-8.533333-8.533333-8.533333h-17.066667c-5.12 0-8.533333 3.413333-8.533333 8.533333s3.413333 8.533333 8.533333 8.533334z"
        p-id="1921"
      />
    </svg>
    <span>无权访问，请联系管理员</span>
  </div>
</template>
<script>
export default {
  name: 'Noaccess',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang='less' scoped>
.column {
  display: flex;
  flex-direction: column;
  // 左右上下居中
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-top: 20px;
    font-size: 20px;
  }
}
</style>
